import React, {Component} from "react";
import "../Tigerboxes.scss";
import "./TigerboxDetails.scss";
import {withToast} from "../../../util/ToastService";
import {ReleaseFromDevice, UpdateDeviceName, GetDeviceIcon} from "../TigerboxSevice";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import {Button, Form, FormControl, InputGroup} from "react-bootstrap";
import {
    BiPencil,
    FaCheck,
    FiBox,
    FiCalendar,
    FiGlobe,
    ImCross,
    IoArrowBackCircleOutline,
    IoCaretBackCircleSharp
} from "react-icons/all";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import GlobalConstants from "../../../config/GlobalConstants";
import {ConfirmationModal} from "../../../global/ConfirmationModal";
import {ConvertDateToReadableString} from "../../../util/ConverterUtil";

class TigerboxDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            device: null,
            isEditingName: false,
            isUpdating: false,
            showRemovingModal: false,
        };

        //Window title
        document.title = "Meine tigerbox :: tiger.media";
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.location.state?.device?.id) {
            this.setState({
                device: this.props.location.state.device
            });
            //Window title
            document.title = "tigerbox " + this.props.location.state.device.displayName + " :: tiger.media";
        } else {
            this.props.history.push("/" + GlobalConstants.APP_PATH + "tigerboxes");
        }
    }

    updateDeviceName() {
        if (this.state.newName) {
            UpdateDeviceName(this.state.device, this.state.newName).then(res => {
                if (!res.error) {
                    this.setState(prevState => ({
                        ...prevState,
                        device: {
                            ...prevState.device,
                            displayName: this.state.newName || prevState.displayName
                        },
                        isEditingName: false
                    }));
                }
            });
        }
    }

    async removeDeviceFromAccount() {
        if (this.state.device && this.state.device.id) {
            let res = await ReleaseFromDevice(this.state.device.id);
            if (!res.error) {
                window.location.href = GlobalConstants.UI_HOST + "tigerboxes";
            }
        }
    }

    getDeviceTypeName = () => {
        if (this.state.device?._embedded?.versions?.display) {
            return this.state.device._embedded?.versions?.display;
        } else {
            return Strings.DEVICES_DETAILS_TYPE_NAME_TIGERBOX_TOUCH;
        }
    }

    render() {
        let softwareVersion = "";
        if (this.state.device) {
            softwareVersion = this.state.device.currentVersion;
            if (softwareVersion && softwareVersion.indexOf("R1.00") < 0) { //extract version only from version string of rewritten software, otherwise it's everytime 1.00, which not helps
                softwareVersion = softwareVersion.slice(1, -22);
            }
        }
        return (
            <div className="content-box">
                <div className="content-details-header" style={{backgroundColor: "#838383"}} id="#title">
                    <a href={"/" + GlobalConstants.APP_PATH + "tigerboxes"}>
                        <IoArrowBackCircleOutline />
                    </a>&#xA0;&#xA0;{Strings.DEVICES_DETAILS_LABEL.replace("{0}", this.getDeviceTypeName())}
                </div>
                <div className="mb-4">
                    {!this.state.loading && this.state.device && (
                        <>
                            <div className="device-details-wrap">
                                <div className="cover-container">
                                    <div className="cover">
                                        <img src={GetDeviceIcon(this.state.device)} alt="cover" className="cover-img"/>
                                    </div>
                                </div>
                                <div className="py-4 px-2 w-100">
                                    <div>
                                        {this.state.isEditingName ? (
                                            <InputGroup className="w-100 mb-2">
                                                <FormControl
                                                    size="lg"
                                                    defaultValue={this.state.device.displayName}
                                                    isInvalid={this.state.newName == null || this.state.newName === ""}
                                                    onChange={e => this.setState({ newName: e.target.value })}
                                                />

                                                <Button
                                                    variant="danger"
                                                    onClick={() =>
                                                        this.setState({
                                                            isEditingName: false,
                                                            newName: this.state.device.displayName
                                                        })
                                                    }
                                                >
                                                    <ImCross />
                                                </Button>
                                                <Button
                                                    disabled={this.state.newName == null || this.state.newName === ""}
                                                    variant={(this.state.newName == null || this.state.newName === "") ? "secondary" : "success"}
                                                    onClick={() => this.updateDeviceName()}
                                                >
                                                    <FaCheck />
                                                </Button>
                                                <Form.Control.Feedback type="invalid">
                                                    {Strings.DEVICES_DETAILS_NAME_ERROR}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        ) : (
                                            <h2>
                                                {this.state.device.displayName}
                                                {"  "}
                                                <Button
                                                    variant="outline-secondary"
                                                    className="px-1 py-0 ml-2"
                                                    onClick={() => this.setState({isEditingName: true, newName: this.state.device.displayName})}
                                                >
                                                    <BiPencil />
                                                </Button>
                                            </h2>
                                        )}
                                    </div>
                                    <hr />
                                    <div className="device-infos-wrap">
                                        <div className="px-1 w-100 device-general-details">
                                            {this.getDeviceTypeName()}
                                        </div>
                                    </div>
                                    <div className="device-infos-wrap">
                                        <div className="px-1 w-100 device-general-details">
                                            {Strings.DEVICES_DETAILS_DEVICE_IDENTIFIER_LABEL} {this.state.device.deviceIdentifier}
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="device-infos-wrap">
                                        <div className="px-1 w-100">
                                            <FiBox/> {Strings.DEVICES_DETAILS_DEVICE_VERSION_LABEL}
                                        </div>
                                        <div className="px-1 w-100">
                                            <div className="device-infos-wrap">
                                                <div className="device-info">
                                                    {softwareVersion}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="device-infos-wrap">
                                        <div className="px-1 w-100">
                                            <FiGlobe/> {Strings.DEVICES_DETAILS_DEVICE_LOCAL_IP_ADDRESS_LABEL}
                                        </div>
                                        <div className="px-1 w-100">
                                            <div className="device-infos-wrap">
                                                <div className="device-info">
                                                    {this.state.device.localIpAddress}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.device.firstOnboardedDateCurrentAccount != null &&
                                        <div className="device-infos-wrap">
                                            <div className="px-1 w-100">
                                                <FiCalendar/> {Strings.DEVICES_DETAILS_DEVICE_FIRST_ONBOARDED_AT_LABEL}
                                            </div>
                                            <div className="px-1 w-100">
                                                <div className="device-infos-wrap">
                                                    <div className="device-info">
                                                        {ConvertDateToReadableString(this.state.device.firstOnboardedDateCurrentAccount)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <br />
                            <hr />
                            {this.state.device &&
                                <TigerButton
                                    className="btn-ci-sm btn-ci-hover mx-auto"
                                    variant="red"
                                    onClick={() => this.setState({showRemovingModal: true})}
                                >
                                    <IoCaretBackCircleSharp /> {Strings.DEVICES_REMOVE_FROM_ACCOUNT_BUTTON}
                                </TigerButton>
                            }
                        </>
                    )}

                    {this.state.loading && <TigerSpinner/>}
                </div>

                <ConfirmationModal
                    title={Strings.DEVICES_REMOVE_WARNING_TITLE}
                    text={Strings.DEVICES_REMOVE_WARNING_BODY.replace("{0}", this.state.device && this.state.device.displayName)}
                    actionButtonLabel={Strings.DEVICES_REMOVE_WARNING_BUTTON}
                    actionButtonVariant="danger"
                    onActionPress={() => this.removeDeviceFromAccount()}
                    show={this.state.showRemovingModal}
                    onHide={() => this.setState({showRemovingModal: false})}
                />
            </div>
        )
    }
}

export default withToast(TigerboxDetails);