import {GetShopResource} from "../../api/SpineClient";
import GlobalConstants from "../../config/GlobalConstants";
import {GetFilteredRestResource} from "../../api/RestResourceClient";

//API METHODS
export async function GetClusterWithProducts(clusterId, page) {
    let cluster = await GetShopResource('api/clusters/' + clusterId);
    //{"id":346,"name":"3Berlin","visibility":true,"type":"HERO","assetId":205723,"order":140,"resizeMethod":"CONTAIN","layoutItems":{"2":{"id":664,"appId":2,"type":"AUDIO_BOOKS","shopLayoutImages":[{"ordinal":1,"sdImage":"https://cdn.tigerbooks.de/ShopLayout/Character/3Berlin.png","hdImage":"https://cdn.tigerbooks.de/ShopLayout/Character/3Berlin.png","title":"3Berlin","query":{"id":103223,"name":"3Berlin","author":null,"ageRangeMin":null,"ageRangeMax":null,"productType":"AUDIOBOOK","productClassifications":[],"categoryCodes":["cat.series.3berlin"],"imprintName":null,"productState":null,"productGroupId":474920005},"productIdentifier":[],"sortBy":"publicationDate","sortDirection":"DESC","ageFiltered":false}]},"3":{"id":665,"appId":3,"type":"AUDIO_BOOKS","shopLayoutImages":[{"ordinal":1,"sdImage":"https://cdn.tigerbooks.de/ShopLayout/Character/3Berlin.png","hdImage":"https://cdn.tigerbooks.de/ShopLayout/Character/3Berlin.png","title":"3Berlin","query":{"id":103224,"name":"3Berlin","author":null,"ageRangeMin":null,"ageRangeMax":null,"productType":"AUDIOBOOK","productClassifications":[],"categoryCodes":["cat.series.3berlin"],"imprintName":null,"productState":null,"productGroupId":474920005},"productIdentifier":[],"sortBy":"publicationDate","sortDirection":"DESC","ageFiltered":false}]}},"_links":{"icon":{"href":"https://s3.eu-central-1.amazonaws.com/tigerbooks.production.public/3d17f234-a16f-4f6c-8f16-e407a4137e83%2F3Berlin.png"}}}
    //let cluster = {"id":4,"name":"Bibi Blocksberg","visibility":true,"type":"HERO","assetId":215845,"order":480,"resizeMethod":"CONTAIN","layoutItems":{"2":{"id":171,"appId":2,"type":"AUDIO_BOOKS","shopLayoutImages":[{"ordinal":1,"sdImage":"https://cdn.tigerbooks.de/ShopLayout/Character/BiBlocks-Cluster.png","hdImage":"https://cdn.tigerbooks.de/ShopLayout/Character/BiBlocks-Cluster.png","title":"Bibi Blocksberg","query":{"id":104581,"name":"Bibi Blocksberg","author":null,"ageRangeMin":null,"ageRangeMax":null,"productType":"AUDIOBOOK","productClassifications":[],"categoryCodes":["cat.character.bibiblocksberg"],"imprintName":null,"productState":null,"productGroupId":474920005},"productIdentifier":[],"sortBy":"popularity","sortDirection":"DESC","ageFiltered":false}]},"3":{"id":3,"appId":3,"type":"AUDIO_BOOKS","shopLayoutImages":[{"ordinal":3,"sdImage":"https://cdn.tigerbooks.de/ShopLayout/Character/BiBlocks-Cluster.png","hdImage":"https://cdn.tigerbooks.de/ShopLayout/Character/BiBlocks-Cluster.png","title":"Bibi Blocksberg","query":{"id":104582,"name":"Bibi Blocksberg","author":null,"ageRangeMin":null,"ageRangeMax":null,"productType":"AUDIOBOOK","productClassifications":[],"categoryCodes":["cat.character.bibiblocksberg"],"imprintName":null,"productState":null,"productGroupId":474920005},"productIdentifier":[],"sortBy":"popularity","sortDirection":"DESC","ageFiltered":false}]}},"_links":{"icon":{"href":"https://s3.eu-central-1.amazonaws.com/tigerbooks.production.public/ea1bdf8d-872a-405e-b915-1e6b40aa260a%2FBI_240.png"}}};
    if (cluster == null) {
        return {products: []};
    }
    let clusterProducts = [];
    let totalPages = 0;
    let totalResults = 0;
    let number = 0;
    if (cluster.layoutItems != null &&
        cluster.layoutItems[GlobalConstants.APP_ID_TIGERTONES] != null &&
        cluster.layoutItems[GlobalConstants.APP_ID_TIGERTONES].shopLayoutImages != null) {
        const queryList = cluster.layoutItems[GlobalConstants.APP_ID_TIGERTONES].shopLayoutImages.filter(i => (i.query != null && i.query.id != null));
        if (queryList.length > 0) {
            const query = queryList[0].query;

            const request = {
                ageRangeMax: query.ageRangeMax,
                ageRangeMin: query.ageRangeMin,
                categoryCodes: query.categoryCodes.join(","),
                imprintName: query.imprintName,
                productClassification: query.productClassifications.join(","),
                productGroupId: query.productGroupId,
                state: query.productState,
                productType: query.productType,
                sortBy: queryList[0].sortBy,
                sortDirection: queryList[0].sortDirection,
                page : page,
                per_page: 50
            }

            let response = await GetFilteredRestResource('api/searches/products-by-clients', request, null, false, true);
            let products = response.result._embedded.simpleHalRepresentationModels;
            totalPages = response.result.page.totalPages;
            totalResults = response.result.page.totalElements;
            number = response.result.page.number;

            if (products) {
                clusterProducts = products.filter(p => (p.state !== "INACTIVE" && p.state !== "TAKEDOWN"));
            }
        }
    }
    cluster.products = clusterProducts;
    cluster.totalPages = totalPages;
    cluster.totalResults = totalResults;
    cluster.number = number;
    return cluster;
}