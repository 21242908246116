import React, { Component } from "react";
import { Card, Col } from "react-bootstrap";
import { TigerSpinner } from "../../global/tiger-spinner/TigerSpinner";
import { FiTrash, GiHamburgerMenu } from "react-icons/all";
import logo from "../../../assets/images/loader_bg.png";
import Strings from "../../config/Strings";
import { TigerButton } from "../../global/tiger-button/TigerButton";
import {GetProductTitle, GetProduct} from "../products/ProductService";

class WatchlistDetails extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            product: null
        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.productId !== this.props.productId) {
            await this.loadProduct();
        }
    }

    async componentDidMount() {
        await this.loadProduct();
    }

    async loadProduct() {
        if (this.props.productId != null) {
            let response = await GetProduct(this.props.productId);

            if (response.error === false) {
                const product = response.result._embedded.simpleHalRepresentationModels[0];

                //We only want to show products with state = 'ACTIVE'
                if(product.state === 'ACTIVE') {
                    this.setState({
                        product: product,
                        loading: false
                    });
                } else {
                    this.setState({
                        product: null,
                        loading: false
                    });
                }
            } else {
                this.setState({
                    product: null,
                    loading: false
                });
            }
        }
    }

    render() {
        const p = this.state.product;
        let title = GetProductTitle(p);

        return (
            <>
            {!this.state.loading && p && (
            <Card className="rounded-card m-0 watchlist-item  justify-content-between">
                <Col className="watchlist-item-cover-container">
                    {this.state.product.cover ? (
                        <img
                            src={this.state.product.cover.contentLocation.location}
                            className="w-100"
                            alt=""
                        />
                    ) : (
                        <img
                            src={logo}
                            className="w-100"
                            style={{ opacity: "0.6" }}
                            alt=""
                        />
                    )}
                </Col>
                <Col className="pl-0">
                    <Card.Body className="h-100 pl-0">
                        <Card.Title className={"watchlist-item-title"}><a
                                    href={"/product/" + p.id}>{title}</a></Card.Title>
                        <Card.Text dangerouslySetInnerHTML={{__html:p.description}}></Card.Text>
                    </Card.Body>
                </Col>
                <div className="d-flex flex-column justify-content-between">
                    <h3 className="text-right"><GiHamburgerMenu/></h3>
                    <TigerButton
                            className="btn-ci-sm btn-ci-hover delete-watchlist-item-button"
                            style={{alignSelf: "end"}}
                            variant="red"
                            noOutline="true"
                            onClick={this.props.removeAudiobook}
                            disabled={this.props.disableDelete}
                    >
                        <FiTrash/> {Strings.WATCHLIST_REMOVE_BUTTON_LABEL}
                    </TigerButton>
                </div>
            </Card>
            )}

                {this.state.loading && (
                        <TigerSpinner/>
                )}
            </>
        );
    }
}

export default WatchlistDetails;
