import React from "react";
import { Modal } from "react-bootstrap";
import {TigerSpinner} from "../../../../../global/tiger-spinner/TigerSpinner";
import {TigerMessage} from "../../../../../global/tiger-message/TigerMessage";
import Strings from "../../../../../config/Strings";
import {TigerButton} from "../../../../../global/tiger-button/TigerButton";

export const UploadInformation = (props) => {
    const {step = 1, show, onHide} = props;
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            style={{borderRadius: "6px"}}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {step === 1 ? (
                        "Verarbeitung"
                    ) : step === 2 ? (
                        "Jetzt wird's wild!"
                    ) : (
                        "Fehler"
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {step === 1 ? (
                    <>
                        <TigerSpinner />
                        <p>Die hochgeladenen Daten werden für die Nutzung mit deiner wildcard verarbeitet</p>
                    </>
                ) : step === 2 ? (
                    <>
                        <TigerMessage text="Deine wildcard ist bereit für deine tigerbox TOUCH!" />
                    </>
                ) : (
                    <p>error</p>
                )}
            </Modal.Body>
            <Modal.Footer className="h-100 d-flex justify-content-center">
                {step !== 1 && (
                    <TigerButton
                        className="btn-ci-sm btn-ci-hover"
                        variant="red"
                        noOutline="true"
                        onClick={onHide}
                    >
                        {Strings.MODAL_CLOSE_BUTTON}
                    </TigerButton>
                )}
            </Modal.Footer>
        </Modal>
    );
}