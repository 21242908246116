import React, { Component } from "react";
import "./Search.scss";
import Strings from "../../config/Strings";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import SearchResultItem from "./SearchResultItem";
import SeriesResultItem from "./SeriesResultItem";
import {GetFilteredProducts, GetStartpage} from "./SearchService";
import {withToast} from "../../util/ToastService";
import {Form} from "react-bootstrap";
import {PaginationHelp} from "../../global/pagination-help/PaginationHelp";
import {FaSearch} from "react-icons/all";
import {Helmet} from "react-helmet";

class SearchResult extends Component {
    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search);
        this.state = {
            loading: false,
            filter: props.state != null ? props.state.filter : {
                page: 0,
                totalPages: 0,
                query: queryParams.get("query") ? queryParams.get("query") : "",
                states: ['ACTIVE'],
                language: "",
                ageMin: "",
                ageMax: "",
                classification: ['RADIOPLAY', 'AUDIOBOOK', 'MUSIC'],
                productType: ['AUDIOBOOK'],
                visibilityCountry: "DE",
                productGroupId: [],
                searchRange: ['ALL'],
                per_page: 20,
                sortBy: "",
                sortDirection: "",
            },
            products: [],
            series: [],
            top10: [],
            top10music: [],
            randomSeries: [],
        };
    }

    async componentDidUpdate(prevProps) {
    }

    async componentDidMount() {
        document.title = "Suche :: tiger.media";
        if (this.state.filter.query && this.state.filter.query.length > 0) {
            await this.searchProducts(0);
        } else {
            await this.getStartPage();
        }
    }

    async getStartPage() {
        this.setState({
            loading: true,
        });

        const response = await GetStartpage();
        let top10 = [];
        let top10music = [];
        let randomSeries = [];
        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            top10 = response.result._embedded.top10;
            top10music = response.result._embedded.top10music;
            randomSeries = response.result._embedded.series;
        }

        this.setState(prevState => ({
            ...prevState,
            top10: top10,
            top10music: top10music,
            randomSeries: randomSeries
        }));

        this.setState({
            loading: false,
        }, () => this.scrollTo());

    }


    async searchProducts(page) {
        this.setState({
            loading: true,
        });

        let filter = this.state.filter;
        filter.page = page;

        const searchResult = await GetFilteredProducts(filter);
        let filteredProducts = [];
        let filteredSeries = [];
        let totalPages = 0;
        if (searchResult.error === true) {
            this.setState(prevState => ({...prevState, error: searchResult}));
        } else {
            filteredProducts = searchResult.result._embedded.products._embedded.page;
            totalPages = searchResult.result._embedded.products.page.totalPages;
            page = searchResult.result._embedded.products.page.number;
            filteredSeries = searchResult.result._embedded.series;
        }

        this.setState(prevState => ({
            ...prevState,
            products: filteredProducts,
            series: filteredSeries,
            filter: {...prevState.filter,
                        page: page,
                        totalPages: totalPages}
        }));

        this.setState({
            loading: false,
        }, () => this.scrollTo());
    }

    scrollTo() {
        window.scrollTo(0, 0)
        if (this.state.filter.query) {
            window.history.pushState({}, null, "/search/?query=" + this.state.filter.query);
        }
    }

    handlePageClick = (event) => {
        console.log(`User requested page number ${event.selected}`);
        window.scrollTo(0, 0)
        this.setState(prevState => ({...prevState, page: event.selected}), () => this.searchProducts(event.selected));
    };

    render() {
        const products = this.state.products;
        const series = this.state.series;

        const randomSeries = this.state.randomSeries;
        const top10 = this.state.top10;
        const top10music = this.state.top10music;

        if(this.state.loading) {
            return (
                <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "20px"}}>
                    <br />
                    <br />
                    <br />
                    <TigerSpinner />
                    <br />
                    {Strings.PRODUCT_DETAILS_REFRESH} <span className="refresh-link" onClick={() => {
                        window.location.reload(false);
                }}>{Strings.PRODUCT_DETAILS_REFRESH_LINK}</span>
                </div>
            )
        }

        return (
            products.length > 0 || series.length > 0  ?
                <div className={"search-result"}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{"Suche für tigertones und die tigerbox :: tiger.media"}</title>
                        <meta name="description" content={'Finde heraus und entdecke welche Hörspiele, Hörbücher und Kindermusik es mit deinem tigertones-Zugang auf der tigerbox oder in der App zu hören gibt oder suche nach deinen Lieblings-Hörspielen, -serien und -hörbuchern'} />
                        <meta name="keywords" content="Suche,Serie,Hörspiel,Hörbuch,Kinder,tigerbox,Musik,Folgen,top 10,beliebt,angebot,programm" />
                    </Helmet>

                    <Form onSubmit={() => {
                        this.searchProducts(0);
                    }}>

                        <div className="search-query-input-container">

                            <Form.Control type="search" value={this.state.filter.query}
                                      className="search-query-input"
                                      placeholder={Strings.PROFILE_PREFERENCES_SEARCH}
                                      onChange={(e) => this.setState(prevState => ({...prevState, filter: {
                                          ...prevState.filter,
                                          query: e.target.value
                                      }}))} />
                            <button id={"search-button"} type="submit" aria-label="Suchen"><FaSearch /></button>
                        </div>
{/*
                        <TigerButton className="w-100" variant="blue" type="submit" >
                            {Strings.SEARCH_BUTTON_TEXT}
                        </TigerButton>
*/}
                    </Form>
                    <br />

                    {series.length > 0  &&
                    <>
                    <div className="series-container">
                        <div>
                            <div className="search-title">
                                Serien und Helden (<a className="all-heros-headline-link" href={"/series/all"}>alle</a>)
                            </div>
                            <hr className={"search-hr"}/>
                        </div>
                        <div className="series-items-container-wrapper">
                            <div className="series-items-container">
                                {series.map(s => (
                                        <div className="series-item" key={s.id}>
                                            <SeriesResultItem series={s}/>
                                        </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    </>}

                    {products.length > 0 &&
                    <>
                    <div className="search-container">
                        <div>
                            <div className="search-title">
                                Titel
                            </div>
                            <hr className={"search-hr"}/>
                        </div>
                        <div className="search-items-container">
                            {products.map(p => (
                                <div className="search-item" key={p.id}>
                                    <SearchResultItem product={p}/>
                                </div>
                            ))}
                        </div>
                    </div>
                        <PaginationHelp
                            page={this.state.filter.page}
                            totalPages={this.state.filter.totalPages}
                            handlePageClick={this.handlePageClick}
                        />

                    </>}
                </div>
                :
                    <div className={"search-result"}>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{"Suche für tigertones und die tigerbox :: tiger.media"}</title>
                            <meta name="description" content={'Finde heraus welche Hörspiele, Hörbücher und Kindermusik es für deinen tigertones-Zugang auf der tigerbox gibt und suche nach deinen Lieblings-Hörspielen, Serien und -hörbuchern'} />
                            <meta name="keywords" content="Suche,Serie,Hörspiel,Hörbuch,Kinder,tigerbox,Musik,Folgen,top 10,beliebt" />
                        </Helmet>

                    <div className="search-query-input-container">
                            <Form onSubmit={() => {
                                this.searchProducts(0);
                            }}>
                                    <Form.Control type="search" value={this.state.filter.query}
                                                  placeholder={Strings.PROFILE_PREFERENCES_SEARCH}
                                                  className="search-query-input"
                                                  onChange={(e) => this.setState(prevState => ({...prevState, filter: {
                                                          ...prevState.filter,
                                                          query: e.target.value
                                                      }}))} />
                                    <button id={"search-button"} type="submit"><FaSearch /></button>
                            </Form>
                        </div>
                        {randomSeries.length > 0  &&
                                <div className="series-container">
                                    <div>
                                        <div className="search-title">
                                            Serien aus unserem Programm (<a className="all-heros-headline-link" href={"/series/all"}>alle</a>)
                                        </div>
                                        <hr className={"search-hr"}/>
                                    </div>
                                    <div className="series-items-container-wrapper">
                                        <div className="series-items-container">
                                            {randomSeries.map(s => (
                                                    <div className="series-item" key={s.id}>
                                                        <SeriesResultItem series={s}/>
                                                    </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                        }
                        {top10.length > 0 &&
                                <div className="search-container">
                                    <div>
                                        <div className="search-title">
                                            Top 10 Hörspiele/Hörbücher
                                        </div>
                                        <hr className={"search-hr"}/>
                                    </div>
                                    <div className="search-items-container">
                                        {top10.map(p => (
                                                <div className="search-item" key={p.id}>
                                                    <SearchResultItem product={p}/>
                                                </div>
                                        ))}
                                    </div>
                                </div>
                        }
                        {top10music.length > 0 &&
                                <div className="search-container">
                                    <div>
                                        <div className="search-title">
                                            Top 10 Musik
                                        </div>
                                        <hr className={"search-hr"}/>
                                    </div>
                                    <div className="search-items-container">
                                        {top10music.map(p => (
                                                <div className="search-item" key={p.id}>
                                                    <SearchResultItem product={p}/>
                                                </div>
                                        ))}
                                    </div>
                                </div>
                        }
                        {this.state.filter.query &&
                                <div className="search-container">
                                    <div className={"no-result-container"}>Deine Suche ergab leider keine Treffer</div>
                                </div>}

                    </div>
        );
    }
}

export default withToast(SearchResult);
