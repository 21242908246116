import './ClusterProduct.scss';
import logo from './../../../assets/images/loader_bg.png';
import {FaChild, GiSmartphone} from "react-icons/all";
import {TigerButton} from "../../global/tiger-button/TigerButton";
import React from "react";
import Strings from "../../config/Strings";
import GlobalConstants from "../../config/GlobalConstants";
import {isMobile} from 'react-device-detect';
import {GetProductTitle} from "../products/ProductService";

export default function ClusterProduct(props) {
    const {product} = props;
    const cover = (product != null && product.cover != null && product.cover.contentLocation != null && product.cover.contentLocation.location != null) ?
        product.cover.contentLocation.location :
        logo;

    const openProduct = () => {
        props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "product/" + product.id,
            state: {clusterId: props.clusterId, profileLink: props.profileLink, profileId: props.profileId, isSelected: props.isSelected}
        })
    }

    let title = GetProductTitle(product);

    return(
        <div className="cluster-product-container">
            <div className="cluster-product-wrap">
                <div className="w-10">
                    <img src={cover} alt="Cover" />
                </div>
                <div className="w-80" style={{marginLeft: "10px"}}>
                    <div className="cluster-product-title">{title}</div>
                    <div className="cluster-product-details">
                        {product.ageMin != null && <>&#xA0;<FaChild />&#xA0;{Strings.CLUSTER_PRODUCT_AGE_MIN.replace("{0}", product.ageMin)}&#xA0;&#xA0;</>}
                    </div>
                    <hr />
                    <div className="cluster-product-wrap">
                        {isMobile &&
                        <div className="w-100 cluster-product-button">
                            <a href={product.id != null ? GlobalConstants.TONES_DEEPLINK_TO_PRODUCT + product.id +
                                "&referrer=/product/play/" + product.id : GlobalConstants.REDIRECT_WEBSITE}>
                                <TigerButton className="btn-ci-hover" style={{width: "max-content", margin: "auto"}}
                                             noOutline="true"
                                             variant="red"><GiSmartphone/>&#xA0;{Strings.CLUSTER_PRODUCT_OPEN_IN_TIGERTONES}
                                </TigerButton>
                            </a>
                        </div>
                        }
                        <div className="w-100 cluster-product-button">
                            <TigerButton style={{margin: "auto"}} className="btn-ci-hover" noOutline="true" variant="gray" type="button" onClick={() => openProduct()}>{Strings.CLUSTER_PRODUCT_MORE_DETAILS}</TigerButton>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}