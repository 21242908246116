import "./RegisterForm.scss";
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Strings from "../../../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import GlobalConstants from "../../../config/GlobalConstants";
import {authCheck} from "../../../util/authCheck";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import {InfoModal} from "../../../global/ConfirmationModal";
import {CreateResource} from "../../../api/SpineClient";
import {FaEye, FaEyeSlash} from "react-icons/all";
import {Helmet} from "react-helmet";
import { Modal } from "react-bootstrap";
import headerImg from "../../../../assets/images/rewe/header-registration.png";
import * as billwerkJSWrapper from "../../../api/BillwerkJSWrapper";
import {loginUser} from "../../../authentication/Oauth2Service";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";

const ERROR_CODE_DUPLICATE_EMAIL = "400-03";
const ERROR_CODE_EMAIL_OF_INACTIVE_ACCOUNT = "400-04";
const ERROR_CODE_PASSWORT_TOO_SHORT = "400-01";

export default function RegisterForm({ setToken }) {
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [alreadyLoggedInError, setAlreadyLoggedInError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [unknownError, setUnknownError] = useState(false);
    const [showModalRegistrationInProgress, setShowModalRegistrationInProgress] = useState(false);
    const [showModalDplicateEmail, setShowModalDplicateEmail] = useState(false);

    useEffect(() => {
        if (authCheck()) {
            setAlreadyLoggedInError(true);
        }
    }, []);

    const setField = (field, value, isValid) => {
        setPasswordError(false);
        setUnknownError(false);
        setEmailError(false);

        setForm({
            ...form,
            [field]: value
        });

        if (!isValid) {
            setErrors({
                ...errors,
                [field]: true
            });
        } else {
            setErrors({
                ...errors,
                [field]: false
            });
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitDisable(true);

        let hasErrors = !Object.values(errors).every(x => x === false);
        if (!hasErrors) {
            setShowModalRegistrationInProgress(true);
            setSubmitted(true);

            let {email, password} = form;
            let newUser = {
                'email': email.trim(),
                'password': password,
                'newsletter': false,
                'registerDeviceId': 'rewe-registration-form'
            };

            let response = await CreateResource('api/register', newUser);

            if (!response.error) {
                //log user in otherwise the next calls fails
                let token = await loginUser({
                    email,
                    password
                });

                if (token) {
                    //Token is available -> Set the expiration date/time
                    let expiredAt = new Date();
                    expiredAt.setSeconds(expiredAt.getSeconds() + token.expires_in);
                    token.expires_at = expiredAt.getTime();

                    //add to newly created account the 3 weeks test subscription
                    await submitOrder(newUser, token);
                }
            } else {
                if (response.message != null && (response.message.indexOf(ERROR_CODE_DUPLICATE_EMAIL) > -1 || response.message.indexOf(ERROR_CODE_EMAIL_OF_INACTIVE_ACCOUNT) > -1)) {
                    setShowModalDplicateEmail(true);
                    setShowModalRegistrationInProgress(false);
                    setSubmitDisable(false);
                    setSubmitted(false);
                } else if (response.message != null && response.message.indexOf(ERROR_CODE_PASSWORT_TOO_SHORT) > -1) {
                    setPasswordError(true);
                    setErrors({...errors, password: true});
                    setShowModalRegistrationInProgress(false);
                    setSubmitDisable(false);
                    setSubmitted(false);
                } else {
                    setUnknownError(true);
                    setShowModalRegistrationInProgress(false);
                    setSubmitDisable(false);
                    setSubmitted(false);
                }
            }
        }
    }

    const submitOrder = async (newUser, token) => {
        //Create the request for the purchase service
        const planVariantId = GlobalConstants.REWE_BILLWERK_PLAN_VARIANT_ID;

        let request = {
            Cart: {
                PlanVariantId: planVariantId,
                MeteredUsages: [],
                DiscountSubscriptions: []
            },
            Customer: {
                EmailAddress: newUser.email,
            }
        }

        let response = await CreateResource('api/purchases/billwerk/order/submit', request, true, GlobalConstants.BILL_HOST, token);
        if (response.error) {
            if (response.result == null) {
                console.log(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
            } else if (response.result.errorCode != null && Number(response.result.errorCode) === 24) {
                console.log(Strings.PURCHASE_CONFIRMATION_ACTIVE_SUBSCRIPTION_ERROR.replace("{0}", newUser.email));
            } else {
                console.log(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
            }
            window.location.href = "https://tiger.media/pages/das-hat-leider-nicht-geklappt";
            return;
        }
        let order = response.result;
        let value = 0;
        let billwerkOrder = {OrderId: order.orderId, Currency: order.currency};
        let purchaseData = {};
        purchaseData.orderId = order.orderId;
        purchaseData.value = value;
        purchaseData.currency = order.currency;
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));

        //Billwerk pay request
        let paymentData = {
            bearer: 'None:None',
        }

        const onError = (data) => {
            window.location.href = "https://tiger.media/pages/das-hat-leider-nicht-geklappt";
        }

        const onSuccess = (data) => {
            window.location.href = "https://tiger.media/pages/rewe-erfolgreich";
        }
        billwerkJSWrapper.paySignupInteractive(paymentData, billwerkOrder, onSuccess, onError);
    }

    return (
            <>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{"Registrierung :: tiger.media"}</title>
                    <meta name="description"
                          content={'Registriere deinen tigermedia-Account, um ganze drei Wochen unbeschränkten Zugang zum tigertones-Streamingdienst zu erhalten und alle Folgen deiner Lieblingsdetektive und noch viel mehr streamen.'}/>
                    <meta name="keywords"
                          content={'rewe,aktion,drei fragezeichen kids,tigertones,registrierung,anlegen,tigerbox,account,konto,tigermedia'}/>
                    <link rel="canonical" href={"https://my.tiger.media/rewe/registration"}/>
                </Helmet>

                <div className="rewe-header-overlay">
                    <div>
                    <img src={headerImg}
                         alt={"header"}/>
                    </div>
                </div>

                <div className="rewe-register-form">
                    <Form className="text-left" onSubmit={handleSubmit}>
                        {/* Email */}
                        <Form.Group className="mb-3" controlId="registerFormEmail">
                            <Form.Label className="auth-label">{Strings.REGISTER_FORM_EMAIL}</Form.Label>
                            <Form.Control
                                    type="email"
                                    required
                                    isInvalid={errors.email}
                                    onChange={e => setField('email', e.target.value, e.target.checkValidity())}
                            />

                            <Form.Control.Feedback type="invalid">
                                {emailError ? Strings.REGISTER_EMAIL_IN_USE : Strings.AUTHENTICATION_ERROR_INVALID_EMAIL}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Password */}
                        <Form.Group className="mb-3 password-container" controlId="registerFormPassword">
                            <Form.Label className="auth-label" style={{width: "100%"}}>
                                {Strings.REGISTER_FORM_PASSWORD}
                                <span style={{
                                    fontSize: "18px",
                                    fontWeight: "normal",
                                    color: '#ababab',
                                    marginLeft: "5px"
                                }}>{Strings.REGISTER_FORM_PASSWORD_REQUIREMENTS}</span>
                            </Form.Label>
                            <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    required
                                    isInvalid={errors.password}
                                    onChange={e => setField('password', e.target.value, (e.target.value.length >= 8 && e.target.value.length <= 50))}
                            />
                            <div style={{cursor: "pointer"}} onClick={() => setShowPassword(!showPassword)}  className={"show-password-icon-container"}>
                                {showPassword ? <FaEyeSlash/> : <FaEye/>}
                            </div>
                            <Form.Control.Feedback type="invalid">
                            <span style={{fontSize: "16px"}}>{passwordError ? Strings.REGISTER_PASSWORT_TO_SHORT : Strings.RESET_PASSWORD_ERROR_LENGTH}</span>
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Password Confirmation */}
                        <Form.Group className="mb-3" controlId="registerFormPasswordConfirmation">
                            <Form.Label
                                    className="auth-label">{Strings.REGISTER_FORM_PASSWORD_CONFIRMATION}</Form.Label>
                            <Form.Control
                                    type="password"
                                    required
                                    isInvalid={errors.confirmation}
                                    onChange={e => setField('confirmation', e.target.value, (e.target.value === form.password))}
                            />

                            {(form.confirmation != null && form.confirmation !== "" && form.confirmation !== form.password) &&
                                    <Form.Control.Feedback type="invalid">
                                        <span style={{fontSize: "16px"}}>{Strings.RESET_PASSWORD_ERROR_MATCH}</span>
                                    </Form.Control.Feedback>
                            }
                        </Form.Group>


                        <div>
                            <div className={"round"}>
                                <input name="terms" id="terms" type="checkbox" value={form.terms} onChange={(e) => {
                                    setField('terms', !form.terms, e.target.checked);
                                    if (e.target.checked) {
                                        setSubmitDisable(false);
                                    } else {
                                        setSubmitDisable(true);
                                    }
                                }}/>
                                <label htmlFor="terms"></label>
                            </div>
                            <label className="roundlabel registerCheckboxLabel" htmlFor="conditions">Hiermit akzeptiere ich
                                die <a href="https://tiger.media/policies/terms-of-service" className="black" target="_blank"
                                       rel="noopener noreferrer">AGB</a></label>
                        </div>

                        <TigerButton className="w-100" style={{marginTop: "30px"}} disabled={submitDisable}
                                     variant="red">
                            {(submitDisable && submitted) ?
                                    <Spinner animation="border"/>
                                    : Strings.REGISTER_FORM_REGISTER_BUTTON}
                        </TigerButton>

                    </Form>

                    <p className={"promotion-conditions"}>* 21 Tage kostenloser Zugang zur tigertones-Streamingwelt nach
                        Registrierung mit
                        E-Mail-Adresse.<br/>
                        <br/>
                        Gilt nur im Aktionszeitraum 18.11.2024 – 31.03.2025.<br/>
                        <br/>
                        Der kostenlose Zugang endet in jedem Fall mit Ablauf des Aktionszeitraums, auch wenn die 21 Tage
                        noch nicht abgelaufen sind. Der Zugang endet automatisch. Keine Kündigung notwendig. Gilt nur
                        für Kund:innen, die nicht bei tigertones registriert sind. Es gelten die AGB der Tiger Media
                        Deutschland GmbH (<a href={"https://tiger.media/agb"}>https://tiger.media/agb</a>).</p>
                </div>

                {/* ERROR MODAL */}
                <InfoModal
                        title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                        text={Strings.REGISTER_UNKNOWN_ERROR_BODY}
                        show={unknownError}
                        onHide={() => setUnknownError(false)}
                />

                <InfoModal
                        title={"Fehler bei der Registrierung"}
                        text={Strings.REGISTER_EMAIL_IN_USE}
                        show={showModalDplicateEmail}
                        onHide={() => setShowModalDplicateEmail(false)}
                />

                <Modal show={showModalRegistrationInProgress} fullscreen={"md"}>
                    <Modal.Header style={{backgroundColor: "#a51482", color: "#ffffff", textAlign: "center"}}>
                        <Modal.Title style={{width: "100%"}}>REGISTRIERUNG</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{textAlign: "center", fontSize: "1.3em"}}>Dein Konto für tigertones sowie der kostenlose Zugang für drei Wochen tigertones werden angelegt.<br/><br/>Dies
                        kann ein paar Sekunden dauern. <br/><br/>Bitte den Browser oder Tab nicht schließen.
                        <br/>
                        <TigerSpinner isLoading={true} style={{height: "105px", width: "110px"}}/>
                    </Modal.Body>
                </Modal>

                <Modal show={alreadyLoggedInError && !submitted} fullscreen={"md"}>
                    <Modal.Header style={{backgroundColor: "#a51482", color: "#ffffff", textAlign: "center"}}>
                        <Modal.Title style={{width: "100%"}}>KONTO VORHANDEN</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{textAlign: "center", fontSize: "1.3em"}}>Dieses Angebot ist <br/>nur für
                        tigertones-Neukunden.
                    </Modal.Body>
                </Modal>
            </>
    )
}


RegisterForm.propTypes = {
    setToken: PropTypes.func.isRequired
}