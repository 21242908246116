import {GetFilteredRestResource, GetRestResource} from "../../api/RestResourceClient";

//API METHODS
export async function GetFilteredProducts(filter) {
    return await GetFilteredRestResource('api/searches/products-by-customer', filter, null, false, false,
            false, false);
}

export async function GetStartpage(filter) {
    return await GetRestResource('api/searches/start-page', false, true);
}


export function getProductLength(product) {
    if (product.productType === "MOVIE" || product.productType === "AUDIOBOOK") {
        if (product.attributes.length) {
            const seconds = product.attributes.length
            const minutes = Math.floor(seconds / 60);

            return '<span>' + minutes + "</span> Min";
        } else {
            return null;
        }
    } else {
        return null;
    }
}
