import './ProfilePreferences.scss';
import React, {useState} from "react";
import {FcOk} from "react-icons/all";
import Strings from "../../../../config/Strings";
import {TigerSpinner} from "../../../../global/tiger-spinner/TigerSpinner";
import logo from "../../../../../assets/images/loader_bg.png";
import GlobalConstants from "../../../../config/GlobalConstants";
import {Button, Form} from "react-bootstrap";
import LazyLoad from 'react-lazy-load';

export default function ProfilePreferences(props) {
    const [showSelectedHerosOnly, setShowSelectedHerosOnly] = useState(false);
    const [showSelectedTopicsOnly, setShowSelectedTopicsOnly] = useState(false);
    const [preferencesFilter, setPreferencesFilter] = useState(null);
    const preferencesIds = props.preferences.map(p => p.id);
    const filter = preferencesFilter;

    function clusterSortBy() {
        return function (x, y) {
            if (x.name < y.name) {
                return -1;
            }
            if (x.name > y.name) {
                return 1;
            }
            return 0;
        }
    }

    const heros = (filter != null && filter !== "") ?
        props.clusters.filter(c => c.type === 'HERO' && c.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) :
        props.clusters.filter(c => c.type === 'HERO').sort(clusterSortBy());
    const amountHeros = props.clusters.filter(c => c.type === 'HERO').length;
    const amountSelectedHeros = props.clusters.filter(c => c.type === 'HERO' && preferencesIds.indexOf(c.id) > -1).length;
    const topics = (filter != null && filter !== "") ?
        props.clusters.filter(c => c.type === 'TOPIC' && c.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) :
        props.clusters.filter(c => c.type === 'TOPIC').sort(clusterSortBy());
    const amountTopic = props.clusters.filter(c => c.type === 'TOPIC').length;
    const amountSelectedTopics = props.clusters.filter(c => c.type === 'TOPIC' && preferencesIds.indexOf(c.id) > -1).length;
    const profileName = props.profile.name;

    function getCoverUrl(cluster) {
        if (cluster && cluster._links && cluster._links.icon && cluster._links.icon.href) {
            return cluster._links.icon.href;
        } else {
            return logo;
        }
    }

    function getClusterName(cluster) {
        let name = cluster.name.length > 150 ? cluster.name.substr(0, 150) + '...' : cluster.name;
        if (preferencesIds.indexOf(cluster.id) > -1) {
            return <>{name}&nbsp;<FcOk /></>
        } else {
            return name
        }
    }

    const openCluster = (id, isSelected) => {
        props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "cluster/" + id,
            state: {profileLink: props.profileLink, profileId: props.profile.id, isSelected: isSelected, source: 'preferences'}
        });
    }

    function clusterList(clusters, type) {
        let filteredClusters = clusters;
        if (type === 'HERO') {
            filteredClusters = showSelectedHerosOnly ? clusters.filter(c => preferencesIds.indexOf(c.id) > -1) : clusters;
        } else {
            filteredClusters = showSelectedTopicsOnly ? clusters.filter(c => preferencesIds.indexOf(c.id) > -1) : clusters;
        }
        return (
            <div className={"preferences-items-container"}>
                {filteredClusters.map(c => (
                    <div className="preferences-item" key={c.id}>
                        <LazyLoad height={150} offset={300}>
                            <img className={preferencesIds.indexOf(c.id) > -1 ? "selected-preferences" : ""} src={getCoverUrl(c)} alt="" onClick={() => props.onToggleCluster(c)}/>
                        </LazyLoad>
                        <div className="preferences-text-container">
                            <div className={"preferences-name"} onClick={() => props.onToggleCluster(c)}>{getClusterName(c)}</div>
                            <div style={{cursor: "pointer", color: "#ce023e"}} onClick={() => openCluster(c.id, preferencesIds.indexOf(c.id) > -1 )}>{Strings.PROFILE_PREFERENCES_SHOW_PRODUCTS}&#xA0;&#187;</div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    function noContent(type) {
        return (
            <div className="preferences-no-content">
                <img src={logo} alt="Logo"/><br/><br/>
                {filter != null && filter !== "" ?
                    <h4>{type === 'heros' ? Strings.PROFILE_PREFERENCES_NO_HEROS_FOR_QUERY.replace("{0}", filter) :
                        Strings.PROFILE_PREFERENCES_NO_TOPICS_FOR_QUERY.replace("{0}", filter)}</h4>
                    :
                    <h4>{type === 'heros' ? Strings.PROFILE_PREFERENCES_NO_HEROS :
                        Strings.PROFILE_PREFERENCES_NO_TOPICS}</h4>
                }

            </div>
        )
    }

    return(
            <div className={"profile-preferences-container"}>
                <Form.Control type="text" value={preferencesFilter}
                              className={"profile-preferences-search-query-input"}
                              placeholder={Strings.PROFILE_CLUSTER_SEARCH}
                              onChange={(e) => setPreferencesFilter(e.target.value)}/>
                <div className={"search-link"}><a href={"/search"}>Zur allgemeinen Suche</a></div>

                <div className="content-header preferences-label"
                     style={{color: "#1bafb4"}}>{Strings.PROFILES_HEROS_TITLE.replace("{0}", profileName)}</div>
                <div className="mb-4">
                    {props.loading ?
                            <TigerSpinner/>
                            :
                            <>
                                <h4 className="profiles-amount">
                                    {Strings.PROFILE_PREFERENCES_AMOUNT_HEROS.replace("{0}", amountSelectedHeros).replace("{1}", amountHeros)}
                                </h4>
                                <Button className="preferences-toggle-button"
                                        variant={showSelectedHerosOnly ? "secondary" : "outline-secondary"}
                                        onClick={() => setShowSelectedHerosOnly(!showSelectedHerosOnly)}>
                                    {showSelectedHerosOnly ? Strings.PROFILE_PREFERENCES_SHOW_ALL_HEROS_BUTTON : Strings.PROFILE_PREFERENCES_SHOW_SELECTED_ONLY_HEROS_BUTTON}
                                </Button>
                                <br/>
                                <br/>
                                <br/>
                                {heros.length > 0 &&
                                        <>

                                            {clusterList(heros, 'HERO')}
                                        </>
                                }

                                {heros.length === 0 &&
                                        noContent('heros')
                                }

                            </>
                    }
                    <div className="content-header preferences-label"
                         style={{color: "#1bafb4"}}>{Strings.PROFILES_TOPICS_TITLE.replace("{0}", profileName)}</div>
                    {props.loading ?
                            <TigerSpinner/>
                            :
                            <>
                                <h4 className="profiles-amount">
                                    {Strings.PROFILE_PREFERENCES_AMOUNT_TOPICS.replace("{0}", amountSelectedTopics).replace("{1}", amountTopic)}
                                </h4>
                                <Button className="preferences-toggle-button"
                                        variant={showSelectedTopicsOnly ? "secondary" : "outline-secondary"}
                                        onClick={() => setShowSelectedTopicsOnly(!showSelectedTopicsOnly)}>
                                    {showSelectedTopicsOnly ? Strings.PROFILE_PREFERENCES_SHOW_ALL_TOPICS_BUTTON : Strings.PROFILE_PREFERENCES_SHOW_SELECTED_ONLY_TOPICS_BUTTON}
                                </Button>
                                <br/>
                                <br/>
                                <br/>
                                {topics.length > 0 &&
                                        <>

                                            {clusterList(topics, 'TOPIC')}
                                        </>
                                }

                                {topics.length === 0 &&
                                        noContent('topics')
                                }
                            </>
                    }
                </div>
            </div>
    );
}