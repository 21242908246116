import React, {Component} from "react";
import { Card } from "react-bootstrap";
import Strings from "../../config/Strings";
import {FcPaid, FcCalendar, BiArrowBack} from "react-icons/all";
import { PaginationHelp } from "../../global/pagination-help/PaginationHelp";
import { TigerSpinner } from "../../global/tiger-spinner/TigerSpinner";
import { TigerMessage } from "../../global/tiger-message/TigerMessage";
import {withToast} from "../../util/ToastService";
import {GetFilteredSubscriptions, GetProductsByIaps} from "./SubscriptionService";
import { StatusIndicator } from "../../global/status-indicator/StatusIndicator";
import GlobalConstants from "../../config/GlobalConstants";
import ErrorCodes from "../../config/ErrorCodes";
import {ConvertDateToReadableString} from "../../util/ConverterUtil";


class Subscriptions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            subscriptions: [],
            productIaps: [],
            products: [],
            filter: {
                page: 1,
                per_page: 10,
                articleNotLike: 'tigerticket',
                active_only: false,
                sortBy: "validUntil",
                sortDirection: "DESC"
            },
            lastPage: 1
        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            await this.loadSubscriptions();
        }
    }

    async componentDidMount() {
        await this.loadSubscriptions();
    }

    async loadSubscriptions() {
        this.setState({loading: true});
        if (this.props.accountId != null) {
            let subscriptions = await GetFilteredSubscriptions(this.props.accountId, this.state.filter);

            if (!subscriptions.error) {
                const subscriptionsRes = subscriptions.result;

                this.setState({
                    subscriptions: subscriptionsRes,
                    productIaps: subscriptionsRes.map(s => s.articleId),
                    lastPage: Math.ceil(subscriptions.length / this.state.filter.per_page)
                }, () => this.loadProducts());
            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_SUBSCRIPTION + " - "  + subscriptions.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState({
                    loading: false,
                    subscriptions: []
                });
            }
        }
    }

    async loadProducts() {
        if (this.state.productIaps.length !== 0) {
            let products = await GetProductsByIaps(this.state.productIaps);
            if (!products.error) {
                const productRes = products.result;
                this.setState({
                    products: productRes
                });
            }
            else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_PRODUCT + " - "  + products.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState({
                    loading: false,
                    products: []
                });
            }
        }

        this.setState({loading: false});
    }

    findProductForSubscription = (subscription) => {
        return this.state.products.find(p => p.iapProductIdentifier === subscription.articleId);
    };

    changePage = (page) => {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                page: page
            }
        }), () => this.loadSubscriptions());
    }

    calculateDaysUntill = (exp) => {
        var todayDate = new Date().getTime();
        var endDate = Date.parse(exp);
        var diff = endDate - todayDate;
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    render() {
        return (
            <div className="content-box">
                <div className="back-button">
                    <a href={"/" + GlobalConstants.APP_PATH}>
                        <button
                            className={"btn-ci-outlined back-button btn-ci-hover"}
                            type="button"
                        ><BiArrowBack /> {Strings.BACK_BUTTON}</button>
                    </a>
                </div>
                <div className="content-header" style={{color: '#00b4eb'}}>{Strings.NAV_SUBSCRIPTIONS}</div>
                <div className="mb-4">

                    {!this.state.loading && this.state.subscriptions.length !== 0 &&
                        <>
                            {this.state.subscriptions.map(s => {
                                const subscriptionProduct = s.articleId != null ? this.findProductForSubscription(s) : null;
                                const daysTillExpiration = this.calculateDaysUntill(s.validUntil);
                                return(
                                    <Card key={s.id} className="rounded-card">
                                        <Card.Body>
                                            <StatusIndicator status={s.active === true} />
                                            <Card.Title>
                                                {subscriptionProduct ? subscriptionProduct.title : Strings.SUBSCRIPTIONS_ERROR_UNKNOWN_PRODUCT}
                                            </Card.Title>
                                            <Card.Subtitle className="text-muted"  dangerouslySetInnerHTML={{__html: subscriptionProduct ? subscriptionProduct.description : ""}}/>
                                            <Card.Text className="mt-3">
                                                <span><FcPaid size={24}/> {Strings.SUBSCRIPTIONS_LABEL_PURCHASED} {ConvertDateToReadableString(s.purchaseTime)}</span>
                                                <br/>
                                                <span><FcCalendar size={24}/> {Strings.SUBSCRIPTIONS_LABEL_EXPIRES} {ConvertDateToReadableString(s.validUntil)} </span>
                                                {s.active === true ? 
                                                    <span>({daysTillExpiration < 2 ? Strings.SUBSCRIPTIONS_LABEL_1_DAY_LEFT : daysTillExpiration+" "+Strings.SUBSCRIPTIONS_LABEL_DAYS_LEFT})</span> 
                                                : ""}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                )
                            })}

                            <PaginationHelp
                                page={this.state.filter.page} 
                                totalPages={this.state.lastPage}
                                handlePageClick={this.changePage}
                            />
                        </>
                    }

                    {!this.state.loading && this.state.subscriptions.length === 0 && (
                        <TigerMessage text={Strings.SUBSCRIPTIONS_NOTHING_FOUND}/>
                    )}

                    {this.state.loading && (
                        <TigerSpinner/>
                    )}

                </div>
            </div>
        )
    }
}

export default withToast(Subscriptions);