import React, {Component} from "react";
import "./Wildcards.scss";
import { Button, InputGroup, FormControl, Row } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import Strings from "../../config/Strings";
import { TigerSpinner } from "../../global/tiger-spinner/TigerSpinner";
import { TigerMessage } from "../../global/tiger-message/TigerMessage";
import {withToast} from "../../util/ToastService";
import { AssignWildcard, GetAccountWildcards } from "./WildcardService";
import SingleWildcard from "./single-wildcard/SingleWildcard";
import { isValidWildcardCode } from "./WildcardHelper";
import GlobalConstants from "../../config/GlobalConstants";
import {BiArrowBack} from "react-icons/all";
import ErrorCodes from "../../config/ErrorCodes";

class Wildcards extends Component {

    constructor(props) {
        super(props);

        this.newWildcardInput = React.createRef();
        
        this.state = {
            loading: true,
            wildcards: [],
            wildcardCode: ''
        };

        //Window title
        document.title = "Meine wildcards :: tiger.media";
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            await this.loadWildcards();
        }
        window.scrollTo(0, 0);
    }

    async componentDidMount() {
        await this.loadWildcards();
    }

    async loadWildcards() {
        if (this.props.accountId != null) {
            let wildcards = await GetAccountWildcards();

            if (wildcards.error === false) {
                const wildcardsRes = wildcards.result;

                if (wildcardsRes.page.totalElements !== 0) {
                    this.setState({wildcards: wildcardsRes._embedded.cards});
                }
                this.setState({loading: false});
            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_WILDCARD + " - " + wildcards.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState({
                    loading: false,
                    wildcards: []
                });
            }
        }
    }

    assignNewWildcard = () => {
        if (isValidWildcardCode(this.state.wildcardCode)) {
            AssignWildcard(this.state.wildcardCode).then(res => {
                if (!res.error) {
                    const wildcardRes = res.result;
                    if (this.state.wildcards.findIndex(w => w.uid === wildcardRes.uid) === -1) {
                        this.newWildcardInput.current.value = '';
                        this.setState(prevState => ({
                            wildcardCode: "",
                            wildcards: [
                                ...prevState.wildcards,
                                wildcardRes
                            ]
                        }), () => {
                            this.props.addToast(Strings.WILDCARDS_ASSIGN_SUCCESS, {
                                autoDismiss: true,
                                appearance: 'success',
                                placement: 'bottom-center'
                            });
                        });
                    }
                }
                else {
                    if (res.result != null && res.result.status != null && res.result.status === 404) {
                        this.props.addToast(Strings.WILDCARDS_ASSIGN_ERROR_NOT_FOUND, {
                            autoDismiss: true,
                            appearance: 'error',
                            placement: 'bottom-center'
                        });
                    } else if (res.result != null && res.result.status != null && res.result.status === 403) {
                        this.props.addToast(Strings.WILDCARDS_ASSIGN_ERROR_FORBIDDEN, {
                            autoDismiss: true,
                            appearance: "error",
                            placement: 'bottom-center'
                        });
                    } else {
                        this.props.addToast(Strings.WILDCARDS_ASSIGN_ERROR, {
                            autoDismiss: true,
                            appearance: 'error',
                            placement: 'bottom-center'
                        });
                    }
                }
            })
        }
        else {
            this.props.addToast(Strings.WILDCARDS_ASSIGN_ERROR_INVALID_CODE, {
                autoDismiss: true,
                appearance: 'error',
                placement: 'bottom-center'
            });
        }
    }

    render() {
        return (
            <div className="content-box">
                <div className="back-button">
                    <a href={"/" + GlobalConstants.APP_PATH}>
                        <button
                            className={"btn-ci-outlined back-button btn-ci-hover"}
                            type="button"
                        ><BiArrowBack /> {Strings.BACK_BUTTON}</button>
                    </a>
                </div>
                <div className="content-header" style={{color: '#1eaf8c'}}>{Strings.TITLE_WILDCARDS}</div>
                <div className="mb-4 wildcards-container">

                    <InputGroup className="mb-3">
                        <FormControl
                            name='query'
                            ref={this.newWildcardInput}
                            placeholder={Strings.WILDCARDS_ASSIGN_INPUT_CODE}
                            aria-label={Strings.WILDCARDS_ASSIGN_INPUT_CODE}
                            onChange={e => this.setState({ wildcardCode: e.target.value })}
                        />

                        <Button variant="outline-dark" 
                            onClick={() => this.assignNewWildcard()}
                            disabled={this.state.loading}
                        >
                            <FiPlus/> {Strings.WILDCARDS_ASSIGN_ADD_BUTTON}
                        </Button>

                    </InputGroup>

                    {!this.state.loading && this.state.wildcards.length !== 0 &&
                        <Row className="wildcards-items-container mx-0">
                            {this.state.wildcards.map(w => {
                                return (
                                    <SingleWildcard key={w.code}
                                        wildcard={w}
                                        history={this.props.history}
                                    />
                                )
                            })}
                        </Row>
                    }

                    {!this.state.loading && this.state.wildcards.length === 0 && (
                        <TigerMessage text={Strings.WILDCARDS_NOTHING_FOUND}/>
                    )}

                    {this.state.loading && <TigerSpinner/>}
                </div>
            </div>
        )
    }
}

export default withToast(Wildcards);