import React, {Component} from "react";
import Strings from "../../config/Strings";
import {BiArrowBack} from "react-icons/all";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import {withToast} from "../../util/ToastService";
import GlobalConstants from "../../config/GlobalConstants";
import './Premium.css';
import {GetFilteredSubscriptions} from "../subscriptions/SubscriptionService";
import TicketAccess from "./premium-types/TicketAccess";
import ShopSubscriptionAccess from "./premium-types/ShopSubscriptionAccess";
import IAPAccess from "./premium-types/IAPAccess";
import InactiveAccess from "./premium-types/InactiveAccess";
import './premium-types/PremiumType.css';
import {ConvertDateToReadableString} from "../../util/ConverterUtil";
import {GetActiveBillwerkContractsOfCustomer, GetPlanVariantById} from "../dashboard/DashboardService";
import {GetProductDetails} from "./PremiumService";

class Premium extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            subscription: null,
            forceProductsView: false,
            forceTicketView: false,
            forceInvoicesView: false,
            isActive: false,
            isAppleIap: false,
            isGoogleIap: false,
            isIap: false,
            isTicket: false,
            contract: null,
            product: null,
            nextTarif: null,
            nextProduct: null,
            billwerkProducts: []
        };

        //Window title
        document.title = "Mein Zugang :: tiger.media";
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            await this.loadPremiumData();
        }
        window.scrollTo(0, 0);
    }

    async componentDidMount() {
        await this.loadPremiumData();
    }

    async loadPremiumData() {
        this.setState({loading: true});
        if (this.props.accountId != null) {
            let subscriptionRes = await GetFilteredSubscriptions(this.props.accountId, {active_only: true});
            if (!subscriptionRes.error) {
                if (subscriptionRes.result != null && Array.isArray(subscriptionRes.result) && subscriptionRes.result.length > 0) {
                    const subscription = subscriptionRes.result[0];
                    const isActive = subscription != null;
                    const isAppleIap = isActive && subscription.source != null && subscription.source.indexOf('_APPLE') > -1;
                    const isGoogleIap = isActive && subscription.source != null && subscription.source.indexOf('_GOOGLE') > -1;
                    const isIap = isAppleIap || isGoogleIap;
                    const isTicket = isActive && subscription.articleId != null && subscription.articleId.indexOf('tigerticket') > -1 && !isIap;
                    //let premiumPhaseTypeLabel = await this.getPremiumAccessTypeLabel(isActive, isIap, isTicket);
                    let contract = null;
                    const contractsRes = await GetActiveBillwerkContractsOfCustomer();
                    if (!contractsRes.error && contractsRes.result.length > 0) {
                        contract = contractsRes.result[0];
                    }

                    let product = null;
                    let nextTarif = null;
                    let nextProduct = null;
                    if (contract != null && contract.CurrentPhase != null && contract.CurrentPhase.PlanVariantId != null) {
                        const productRes = await GetPlanVariantById(contract.CurrentPhase.PlanVariantId);
                        if (!productRes.error) {
                            product = productRes.result;
                        }

                        nextTarif = this.getNextTarif(contract);

                        if (nextTarif != null) {
                            const billwerkProductsRes = await GetProductDetails();
                            if (!billwerkProductsRes.error && billwerkProductsRes.result != null) {
                                console.log(billwerkProductsRes.result);
                                const matchingProducts = billwerkProductsRes.result
                                    .filter(p => p.planVariantId === nextTarif.PlanVariantId);
                                nextProduct = matchingProducts.length > 0 ? matchingProducts[0] : null;
                            }
                        }
                    }

                    this.setState(prevState => ({
                        ...prevState,
                        subscription: subscriptionRes.result[0],
                        isActive: isActive,
                        isAppleIap: isAppleIap,
                        isGoogleIap: isGoogleIap,
                        isIap: isIap,
                        isTicket: isTicket,
                        loading: false,
                        contract: contract,
                        product: product,
                        nextTarif: nextTarif,
                        nextProduct: nextProduct
                    }));
                }
            } else {
                this.setState(prevState => ({...prevState, loading: false}));
            }
        } else {
            this.setState({loading: false});
        }
        this.setState({loading: false});
    }

    getNextTarif(activeContract) {
        if (activeContract == null || activeContract.CurrentPhase == null ||
            activeContract.Phases == null || activeContract.PlanVariantId == null) {
            return null;
        }

        const currentPlanVariant = activeContract.PlanVariantId;
        const futurePhases = activeContract.Phases
            .filter(p => p.StartDate != null && new Date(p.StartDate) > new Date())
            .filter(p => p.PlanVariantId != null && p.PlanVariantId !== currentPlanVariant)
            .filter(p => p.Type != null && p.Type === "Normal");
        return futurePhases.length > 0 ? futurePhases[0] : null;
    }

    headerByType() {
        let typeLabel = null;
        let type = null;
        let nextBillDate = null;
        if (this.state.isActive) {
            if (this.state.isTicket) {
                let articleId = this.state.subscription.articleId != null ? this.state.subscription.articleId : "";
                if (articleId.indexOf('swiss') > -1) {
                    type = Strings.DASHBOARD_PREMIUM_TYPE_VALUE_SWISS_TICKET;
                } else {
                    type = Strings.DASHBOARD_PREMIUM_TYPE_VALUE_TICKET;
                }
                typeLabel = Strings.PREMIUM_HEADER_PHASE_TYPE_TICKET;
            } else if (this.state.isGoogleIap) {
                type = Strings.PREMIUM_IAP_TYPE_GOOGLE;
                typeLabel = Strings.PREMIUM_HEADER_PHASE_TYPE_SUBSCRIPTION;
            } else if (this.state.isAppleIap) {
                type = Strings.PREMIUM_IAP_TYPE_APPLE;
                typeLabel = Strings.PREMIUM_HEADER_PHASE_TYPE_SUBSCRIPTION;
            } else {
                const productTypeDescription = (this.state.product != null && this.state.product.planVariantDescription != null) ?
                    "<br/>" + this.state.product.planVariantDescription : "";
                type = this.state.product?.displayName + productTypeDescription;

                if (this.state.contract != null && this.state.contract.EndDate != null) {
                    typeLabel = Strings.PREMIUM_HEADER_PHASE_TYPE_CANCELLED;
                } else if (this.state.contract != null) {
                    typeLabel = Strings.PREMIUM_HEADER_PHASE_TYPE_SUBSCRIPTION;
                    nextBillDate = this.state.contract.NextBillingDate;
                } else {
                    typeLabel = Strings.PREMIUM_HEADER_PHASE_TYPE_INAKTIV;
                }
            }

            return (
                <div className="premium-type-container">
                    <div className="shadow-box my-5">
                        <div className="premium-type-wrapper premium-type-header">
                            <div className="w-50">{typeLabel}</div>
                            <div className="w-100">{ConvertDateToReadableString(this.state.subscription.validUntil)}</div>
                        </div>
                        {nextBillDate != null &&
                        <div className="premium-type-wrapper premium-type-header">
                            <div className="w-50">{Strings.PREMIUM_HEADER_NEXT_BILL_DATE}</div>
                            <div className="w-100">{ConvertDateToReadableString(this.state.contract.NextBillingDate)}</div>
                        </div>
                        }
                        <hr />
                        <div className="premium-type-wrapper premium-type-header">
                            <div className="w-50">{Strings.PREMIUM_TICKET_HEADER_LABEL_TYPE}</div>
                            <div className="w-100" dangerouslySetInnerHTML={{__html: type}}/>
                        </div>

                        {this.state.nextTarif != null && this.state.nextProduct != null &&
                            <>
                                <br />
                                <div className="premium-type-wrapper premium-type-header">
                                    <div className="w-50">{Strings.PREMIUM_NEXT_TARIF}:</div>
                                    <div className="w-100">
                                        {Strings.PREMIUM_NEXT_TARIF_FROM} {ConvertDateToReadableString(this.state.nextTarif.StartDate)}:
                                        <br />
                                        {Strings.PREMIUM_TYPE_SHOP_SUBSCRIPTION}
                                        <br />
                                        {this.state.nextProduct.planVariantDescription}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <div className="premium-type-container">
                    <div className="shadow-box my-5">
                        <div className="premium-type-wrapper premium-type-header">
                            <div className="w-50">{Strings.PREMIUM_HEADER_PHASE_TYPE_STATUS}</div>
                            <div className="w-100" style={{color: "#ce023e"}}>{Strings.PREMIUM_HEADER_PHASE_TYPE_INAKTIV}</div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    premiumContentByType() {
        //Forced views (customer clicked on link in the footer)
        if (this.state.forceProductsView) {
            return (
                <div id="premiumAnchor">
                    <InactiveAccess />
                    {this.footerByType(true, true, true, false)}
                </div>
            )
        } else if (this.state.forceInvoicesView) {
            return (
                <div id="premiumAnchor">
                    <ShopSubscriptionAccess accountId={this.props.accountId} contract={this.state.contract} subscription={this.state.subscription} history={this.props.history}/>
                    {this.footerByType(false, true, this.state.isTicket || this.state.isIap, true)}
                </div>
            );
        } else if (this.state.forceTicketView) {
            return (
                <div id="premiumAnchor">
                    <TicketAccess subscription={this.state.subscription} accountId={this.props.accountId} history={this.props.history} />
                    {this.footerByType(true, false, !this.state.isTicket, true)}
                </div>
            );
        }

        //Open the view depending on the active subscription
        let subscription = this.state.subscription;
        if (!this.state.isActive) {
            //No active premium access
            return (
                <div id="premiumAnchor">
                    <InactiveAccess />
                    {this.footerByType(true, true, false, false)}
                </div>
            );
        } else if (this.state.isTicket) {
            //Ticket
            return (
                <div id="premiumAnchor">
                    <TicketAccess subscription={subscription} accountId={this.props.accountId} history={this.props.history} />
                    {this.footerByType(true, false, false, true)}
                </div>
            );
        } else if (this.state.isIap) {
            //IAP
            return (
                <div id="premiumAnchor">
                    <IAPAccess subscription={subscription} accountId={this.props.accountId} history={this.props.history}/>
                    {this.footerByType(true, true, false, true)}
                </div>
            );
        } else {
            //Billwerk subscription
            return (
                <div id="premiumAnchor">
                    <ShopSubscriptionAccess accountId={this.props.accountId} contract={this.state.contract} subscription={this.state.subscription} history={this.props.history}/>
                    {this.footerByType(false, true, false, true)}
                </div>
            );
        }
    }

    footerByType(showInvoicesLink, showTicketsLink, showCurrentType, showPremiumProducts) {
        return (
            <>
                <hr />
                {showPremiumProducts &&
                    <>
                    <div onClick={() => {
                        this.setState(prevState => ({...prevState, forceTicketView: false, forceInvoicesView: false, forceProductsView: true}));
                        window.scrollTo(0, 0);
                    }}
                         className="premium-footer-link">
                        {Strings.PREMIUM_SHOW_PRODUCTS_LINK}&#187;
                    </div>
                    <a href={"/ticket"} className="premium-footer-link">
                    {Strings.PREMIUM_SHOW_PRODUCTS_LINK}&#187;
                    </a>
                    </>
                }
                {showCurrentType &&
                    <div onClick={() => {
                        this.setState(prevState => ({...prevState, forceTicketView: false, forceInvoicesView: false, forceProductsView: false}));
                        window.scrollTo(0, 0);
                    }}
                         className="premium-footer-link"
                    >
                        {Strings.PREMIUM_SHOW_CURRENT_TYPE}&#187;
                    </div>
                }
                {showTicketsLink &&
                    <div onClick={() => {
                        this.setState(prevState => ({...prevState, forceTicketView: true, forceInvoicesView: false, forceProductsView: false}));
                        window.scrollTo(0, 0);
                    }}
                         className="premium-footer-link"
                    >
                        {Strings.PREMIUM_SHOW_ALL_TICKETS_LINK}&#187;
                    </div>
                }
                {showInvoicesLink &&
                    <div onClick={() => {
                        this.setState(prevState => ({...prevState, forceInvoicesView: true, forceTicketView: false, forceProductsView: false}));
                        window.scrollTo(0, 0);
                    }}
                         className="premium-footer-link">
                        {Strings.PREMIUM_SHOW_ALL_INVOICES_LINK}&#187;
                    </div>
                }
            </>
        );
    }

    render() {
        return (
            <div className="content-box">
                <div className="back-button">
                    <a href={"/" + GlobalConstants.APP_PATH}>
                        <button
                            className={"btn-ci-outlined back-button btn-ci-hover"}
                            type="button"
                        ><BiArrowBack/> {Strings.BACK_BUTTON}</button>
                    </a>
                </div>
                <div className="content-header" style={{color: '#00b4eb'}}>{Strings.NAV_PREMIUM}</div>

                {this.state.loading && (
                    <TigerSpinner/>
                )}

                {!this.state.loading && (
                    <>
                        {this.headerByType()}
                        {this.premiumContentByType()}
                    </>
                )}
            </div>
        )
    }
}

export default withToast(Premium);