import React from "react";
import {useHistory, useParams} from "react-router";
import './Purchase.scss';
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import GlobalConstants from "../../../config/GlobalConstants";
import {GetUnauthorizedResources} from "../../../api/SpineClient";


export default function PurchaseRedirect() {

    //Initialization
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const selectedColor = queryParams.get("selectedColor");
    const componentId = queryParams.get("componentId");
    const discountId = queryParams.get("discountId");
    const {planVariantId} = useParams();

    window.scrollTo(0, 0);

    //Redirect to shop page if plan variant id is null
    if (planVariantId == null) {
        history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=1");
        return null;
    }

    //Load the product
    GetUnauthorizedResources('api/purchases/products/search/by-planvariant-id?planVariantId=' + planVariantId, {}, 'application/json', GlobalConstants.BILL_HOST)
        .then(r => {
            //In case of an error, redirect to the error page
            if (r.error || r.result == null) {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=2");
                return null;
            }

            let product = r.result;

            if (product.type == null) {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=3");
                return null;
            }

            let purchase = {
                productId: product.id,
                duration: product.premiumAccessDuration,
                planVariantId: planVariantId,
                selectedColor : selectedColor,
                componentId : componentId,
                discountId : discountId,
                maxStep: 1
            };


            sessionStorage.setItem('ls.purchase', JSON.stringify(purchase));

            if (product.type === "SUBSCRIPTION") {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription");
            } else if (product.type === "DIGITICKET") {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket");
            } else {
                console.log("Warning: Unsupported product type: " + product.type);
                history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=5");
            }


    });

    //Rendering
    return (
        <div className="purchase-redirect-container">
            <TigerSpinner />
        </div>
    );
}