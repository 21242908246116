import React, {Component} from "react";
import Strings from "../../../config/Strings";
import {BiArrowBack} from "react-icons/all";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import {withToast} from "../../../util/ToastService";
import GlobalConstants from "../../../config/GlobalConstants";
import {GetCustomerSelfService} from "../PremiumService";
import './PremiumSettings.css';


class PremiumSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            contracts: [],
            selectedContract: {invoices: []},
            selfService: {},
            filter: {
                page: 1,
                per_page: 10,
            },
            lastPage: 1
        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            await this.loadPremiumData();
        }
    }

    async componentDidMount() {
        await this.loadPremiumData();
    }

    async loadPremiumData() {
        this.setState({loading: true});
        if (this.props.accountId != null) {
            let selfServiceResponse = await GetCustomerSelfService();

            if(!selfServiceResponse.error) {
                let selfService = selfServiceResponse.result;

                if (Array.isArray(selfService) && selfService.length > 0) {
                    selfService = selfService[0];
                }
                  this.setState(prevState => ({
                      ...prevState,
                      selfService: selfService
                  }));
            } else {
                this.props.addToast(Strings.PREMIUM_LOADING_ERROR, {
                    autoDismiss: true,
                    appearance: "error"
                });
            }
        }
        this.setState({loading: false});
    }

    render() {
        return (
            <div className="content-box">
                <div className="back-button">
                    <a href={"/" + GlobalConstants.APP_PATH + "premium"}>
                        <button
                            className={"btn-ci-outlined back-button btn-ci-hover"}
                            type="button"
                        ><BiArrowBack /> {Strings.BACK_BUTTON}</button>
                    </a>
                </div>
                <div className="content-header" style={{color: '#00b4eb'}}>{Strings.NAV_PREMIUM}</div>

                {this.state.loading && (
                    <TigerSpinner/>
                )}

                {(!this.state.loading && this.state.selfService.Url != null) &&
                <div className="premium-box">
                    <div className="box-title">Meine Zahlungsdaten</div>
                    <div className="box-info">Hier kannst du deine Zahlungsdaten bearbeiten:</div>
                    <div className="box-full">
                        {!this.state.loading &&
                        <iframe key={this.state.selfService.Url} src={this.state.selfService.Url}
                                width="100%"
                                height="1200px"
                                frameBorder={0}
                                title="Billwerk self service"/>
                        }
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default withToast(PremiumSettings);