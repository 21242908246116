import React, { Component } from "react";
import logo from "../../../assets/images/loader_bg.png";
import "./PlaylistDetails.css";
import {BiArrowBack} from "react-icons/all";
import Strings from "../../config/Strings";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import GlobalConstants from "../../config/GlobalConstants";
import {GetFilterQuery, GetProductsOfPlaylist} from "./PlaylistService";
import PlaylistItem from "./PlaylistItem";
import {GetShopResource} from "../../api/SpineClient";
import {withToast} from "../../util/ToastService";

class PlaylistDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            playlist: {
                products: []
            },
            //TODO Use this as soon as filter queries work in spine
            //playlistId: this.props.match.params.id,
            playlistId: null
        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.playlist !== this.props.playlist) {
            await this.loadPlaylist();
        }
    }

    async componentDidMount() {
        await this.loadPlaylist();
    }

    async loadPlaylist() {
        if (this.props.playlist == null && this.state.playlistId != null) {
            this.setState({
                loading: true,
            });

            let playlist = await GetFilterQuery(this.state.playlistId);

            if (playlist != null && playlist.id != null) {
                GetProductsOfPlaylist(playlist.id).then((result) => {

                    //We only want to show products with state = 'ACTIVE'
                    if(result != null && Array.isArray(result) && result.length > 0) {
                        this.setState({
                            playlist: {
                                ...playlist,
                                products: result
                            }
                        });

                    } else {
                        this.setState({
                            playlist: {
                                ...playlist,
                                products: []
                            }
                        });
                    }

                    document.title = playlist.name + " :: tiger.media";

                });
            }
        } else {

            this.setState({
                loading: true,
            });

            let products = await GetShopResource('api/products?' + GlobalConstants.PRODUCT_QUERY_DDF, false);

            this.setState({
                playlist: {
                    name: "Die drei ??? Tour 2022",
                    products: products != null ? products : [],
                },
            });

            //Window title
            document.title = "Die drei ??? Tour 2022 :: tigermedia";
        }

        this.setState({
            loading: false,
        });
    }

    render() {
        const playlist = this.state.playlist;

        if(this.state.loading) {
            return (
                <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "20px"}}>
                    <br />
                    <br />
                    <br />
                    <TigerSpinner />
                    <br />
                </div>
            )
        }

        return (
            playlist.products.length > 0 ?
                <>
                    <div className="playlist-container">
                        <div className="playlist-back-button">
                            <a href={"/" + GlobalConstants.APP_PATH}>
                                <button
                                    className={"btn-ci-outlined back-button btn-ci-hover"}
                                    type="button"
                                ><BiArrowBack /> {Strings.BACK_BUTTON}</button>
                            </a>
                        </div>
                        <br />
                        <div className="playlist-title">
                            {this.state.playlist.name}
                        </div>
                        <div className="playlist-items-container">
                            {playlist.products.map(p => (
                                <div className="playlist-item">
                                    <PlaylistItem product={p} playlist={playlist} />
                                </div>
                            ))}
                        </div>
                    </div>
                </>
                :
                <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "25px"}}>
                    <br />
                    <img src={logo} alt="tigermedia logo" width={100} />
                    <br />
                    <br />
                    <span style={{fontSize: "40px"}}>{Strings.PLAYLIST_ERROR_TITLE}</span>
                    <br />
                    <br />
                    {Strings.PLAYLIST_ERROR_MESSAGE} <a href={"https://tiger.media/"}>{Strings.PRODUCT_DETAILS_ERROR_LINK}</a>
                </div>
        );
    }
}

export default withToast(PlaylistDetails);
