import React from "react";
import "./TigerButton.css";

export const TigerButton = (props) => {
    const { variant = "orange", color, onClick, disabled = false, noOutline = true, type="submit"} = props;

    // variants and colors
    let bgColor = color;
    if (!bgColor) {
        switch (variant) {
            case "orange":
                bgColor = "#f07319";
                break;
            case "red":
            case "danger":
                bgColor = "#e50045";
                break;
            case "gray":
                bgColor = "#6c757d";
                break;
            case "light":
                bgColor = "#9ea8b3";
                break;
            case "green":
                bgColor = "#1eaf8c";
                break;
            case "blue":
                bgColor = "#02abeb";
                break;
            case "purple":
                bgColor = "#a51482"
                break;
            default:
                bgColor = "#f07319";
        }
    }

    return (
        <button 
            className={`btn-ci ${props.className} ${noOutline && 'p-0'} ${!disabled && 'btn-ci-hover'}`} 
            style={{...props.style, backgroundColor: bgColor, borderColor: bgColor}}
            type={type}
            onClick={onClick} 
            disabled={disabled}
        >
            <span>
                {props.children}
            </span>
        </button>
    );
}
