import React, {Component} from "react";
import Strings from "../../config/Strings";
import {withToast} from "../../util/ToastService";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import debounce from 'lodash/debounce';
import { DeleteWatchlistEntry, GetAccountUserWatchlist, UpdateWatchlist } from "./WatchlistService";
import { TigerMessage } from "../../global/tiger-message/TigerMessage";
import { TigerSpinner } from "../../global/tiger-spinner/TigerSpinner";
import WatchlistDetails from "./WatchlistDetails";
import { ConfirmationModal } from "../../global/ConfirmationModal";
import GlobalConstants from "../../config/GlobalConstants";
import {BiArrowBack} from "react-icons/all";

class ProfileWatchlist extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            profileId: null,
            accountId: null,
            profileLink: null,
            watchlist: [],
            prevItems: [],
            showRemoveModal: false,
            productForRemoval: null,
            disableDelete: false
        };

        this.onDragEnd = this.onDragEnd.bind(this);

        //Window title
        document.title = "Meine Merkliste :: tiger.media";
    }

    async componentDidMount() {
        if (this.props.location.state && this.props.location.state.profileId && this.props.location.state.accountId) {
            this.setState(prevState => ({
                ...prevState,
                profileId: this.props.location.state.profileId,
                profileLink: this.props.location.state.profileLink,
                accountId: this.props.location.state.accountId
            }), () => this.loadWatchlist());
        } else if (this.props.history !== undefined){
            this.props.history.push({
                pathname: "/" + GlobalConstants.APP_PATH + "profile-details",
                state: this.props.location.state != null ? this.props.location.state.profileLink : null
            });
        }
        window.scrollTo(0, 0);
    }

    async loadWatchlist() {
        if (this.state.accountId != null && this.state.profileId) {
            GetAccountUserWatchlist(this.state.accountId, this.state.profileId).then((watchlist) => {
                if (watchlist.result._embedded) {
                    const list = watchlist.result._embedded.watchListEntryList;
                    this.setState({
                        watchlist: list,
                        prevItems: list
                    });
                }
                else {
                    this.setState({
                        watchlist: [],
                        prevItems: []
                    });
                }
                this.setState({loading: false});
            });
        }
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (sourceIndex === destinationIndex) {
            return;
        }

        const items = this.reorder(
            this.state.watchlist,
            sourceIndex,
            destinationIndex
        );

        this.setState({
            watchlist: items,
            disableDelete: true
        });
    
        this.saveItems();
    }

    saveItems = debounce(() => {
        
        let newArray = this.state.watchlist;

        for (let i = 0; i < newArray.length; i++) {
            newArray[i].orderIndex = i+1;
        }

        UpdateWatchlist(this.state.accountId, this.state.profileId, newArray).then(response => {
            if (!response.error) {
                this.props.addToast(Strings.WATCHLIST_ORDER_SAVED_SUCCESS, {
                    autoDismiss: true,
                    appearance: 'success',
                    placement: 'bottom-center'
                });
                this.setState(prevState => ({
                    prevItems: prevState.watchlist,
                    disableDelete: false
                }));
            }
            else {
                this.props.addToast(Strings.WATCHLIST_ORDER_SAVED_ERROR, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState(prevState => ({
                    watchlist: prevState.prevItems,
                    disableDelete: false
                }));
            }
        });

    }, 2000);

    showModal = (productId, index) => {
        this.setState({
            showRemoveModal: true,
            productForRemoval: {
                id: productId,
                index: index
            }
        });
    }

    removeItem = () => {
        if (this.state.productForRemoval != null) {

            DeleteWatchlistEntry(this.state.accountId, this.state.profileId, this.state.productForRemoval.id).then(response => {
                if (!response.error) {
                    let newArray = this.state.watchlist;
                    newArray.splice(this.state.productForRemoval.index, 1);

                    this.props.addToast(Strings.WATCHLIST_REMOVE_SUCCESS, {
                        autoDismiss: true,
                        appearance: 'success',
                        placement: 'bottom-center'
                    });
        
                    this.setState({
                        watchlist: newArray,
                        showRemoveModal: false,
                        productForRemoval: null
                    });
                }
                else {
                    this.props.addToast(Strings.WATCHLIST_REMOVE_ERROR + response.message, {
                        autoDismiss: true,
                        appearance: 'error',
                        placement: 'bottom-center'
                    });
                    this.setState({
                        showRemoveModal: false,
                        productForRemoval: null,
                        disableDelete: false
                    });
                }
            });
        }
    };

    closeWatchlist = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "profile-details",
            state: this.state.profileLink
        })
    }

    render() {
        return (
            <div className="content-box watchlist-container">
                <div className="back-button">
                    <button
                        className={"btn-ci-outlined back-button btn-ci-hover"}
                        type="button"
                        onClick={() => this.closeWatchlist()}
                    ><BiArrowBack /> {Strings.BACK_BUTTON}</button>
                </div>
                <div className="content-header" style={{color: '#f0731a'}}>{Strings.TITLE_WATCHLIST}</div>
                <div className="mb-4">

                    {this.state.loading && (
                        <TigerSpinner/>
                    )}

                    {!this.state.loading && (
                        <div className="mt-4 watchlist-items-container">

                            {!this.state.loading && this.state.watchlist.length !== 0 && (
                                <>
                                    <DragDropContext onDragEnd={this.onDragEnd} className="m-0 p-0">
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className="w-100 select-none m-0 p-0"
                                                >
                                                    {this.state.watchlist.map((item, index) => (
                                                        <Draggable key={item.productId} draggableId={"item-"+item.productId} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <WatchlistDetails key={item.productId}
                                                                                      productId={item.productId}
                                                                                      removeAudiobook={() => this.showModal(item.productId, index)}
                                                                                      disableDelete={this.state.disableDelete}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </>
                            )}

                            {!this.state.loading && this.state.watchlist.length === 0 && (
                                <TigerMessage text={Strings.WATCHLIST_EMPTY} />
                            )}

                        </div>
                    )}

                </div>

                <ConfirmationModal
                    title={Strings.WATCHLIST_MODAL_REMOVE_TITLE}
                    text={Strings.WATCHLIST_MODAL_REMOVE_TEXT}
                    actionButtonLabel={Strings.WATCHLIST_MODAL_REMOVE_BUTTON_LABEL}
                    actionButtonVariant="danger"
                    onActionPress={() => this.removeItem()}
                    show={this.state.showRemoveModal}
                    onHide={() => this.setState({showRemoveModal: false})}
                />
            </div>

        )
    }
}

export default withToast(ProfileWatchlist);